@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'), url('./assets//fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Regular';
  src: local('Roboto Regular'), url('./assets//fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'), url('./assets//fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'), url('./assets//fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  src: local('Roboto Black'), url('./assets//fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Light';
  src: local('Lato Light'), url('./assets//fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Regular';
  src: local('Lato Regular'), url('./assets//fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Medium';
  src: local('Lato Medium'), url('./assets//fonts/Lato-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Semibold';
  src: local('Lato Semibold'), url('./assets//fonts/Lato-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Bold';
  src: local('Lato Bold'), url('./assets//fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  src: local('Poppins Light'), url('./assets//fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Regular';
  src: local('Poppins Regular'), url('./assets//fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Medium';
  src: local('Poppins Medium'), url('./assets//fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Semibold';
  src: local('Poppins Semibold'), url('./assets//fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), url('./assets//fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Black';
  src: local('Poppins Black'), url('./assets//fonts/Poppins-Black.ttf') format('truetype');
}
